<template>
  <div class="lead">
    <Loader v-if="isLoading" />
    <v-card class="px-4">
      <v-card-title> Lead </v-card-title>
      <v-alert v-if="status == 'ok'" type="success"> Sent succesfully </v-alert>
      <v-alert v-if="status == 'error'" type="error">
        Something is wrong
      </v-alert>
      <v-card-text>
        <v-form
          v-model="validate"
          @submit.prevent="login"
          ref="form"
          lazy-validation
        >
          <v-row>
            <!--            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="E-mail"
                :rules="[rules.required, rules.loginEmailRules]"
                required
              ></v-text-field>
            </v-col>-->
            <div class="error-message" v-if="isValidName">
              You must add at least one of the names
            </div>
            <v-col cols="12">
              <div class="text-field__wrap">
                <div class="text-field__icon">
                  <i class="icon-icons8-instagram"></i>
                </div>
                <div class="text-field__input">
                  <v-text-field
                    v-model="nameIg"
                    label="Instagram username"
                    :rules="[rules.fieldMustBeWithoutSpaces]"
                    outlined
                    required
                  ></v-text-field>
                </div>
              </div>
              <!--              <div class="text-field__wrap">-->
              <!--                <div class="text-field__icon">-->
              <!--                  <i class="icon-icons8-facebook"></i>-->
              <!--                </div>-->
              <!--                <div class="text-field__input">-->
              <!--                  <v-text-field-->
              <!--                    v-model="nameFb"-->
              <!--                    label="Facebook username"-->
              <!--                    :rules="[rules.fieldMustBeWithoutSpaces]"-->
              <!--                    required-->
              <!--                    outlined-->
              <!--                  ></v-text-field>-->
              <!--                </div>-->
              <!--              </div>-->
              <v-select
                v-model="sdr"
                :items="['Vicky', 'Nate', 'Dan']"
                label="SDR"
                outlined
              ></v-select>
              <v-select
                v-model="domain"
                :items="['real estate', 'beauty']"
                label="Domain"
                outlined
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
            <v-spacer></v-spacer>
            <v-col
              class="d-flex justify-center"
              cols="12"
              sm="3"
              xsm="12"
              align-center
            >
              <v-btn @click="run" x-large block color="success"> Run </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <div class="request-info">
        <div class="cell" v-if="requestIgData">
          <div class="cell__title">SMS based on IG insights</div>
          <div>
            {{ requestIgData }}
            <CopyButton :data="requestIgData" />
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formValidationMixin from "@/shared/validationRules";
import CopyButton from "@/components/global/CopyButton";
import Loader from "@/components/global/Loader";

export default {
  data: () => ({
    email: "",
    nameFb: "",
    nameIg: "",
    textWipData: {},
    sdr: "Vicky",
    domain: "real estate",
    remember: false,
    isValidName: false,
    status: "",
    requestIgData: "",
    validate: false,
    isLoading: false,
  }),
  components: { Loader, CopyButton },
  watch: {
    nameFb() {
      this.isValidName = false;
    },
    nameIg() {
      this.isValidName = false;
    },
  },
  computed: {},
  mixins: [formValidationMixin],
  methods: {
    ...mapActions("lead", ["fetchLead"]),
    run() {
      this.status = "";
      this.requestIgData = "";
      if (!this.nameFb.length && !this.nameIg.length) {
        this.isValidName = true;
      }
      if (this.$refs.form.validate() && !this.isValidName) {
        [
          { val: this.nameFb, name: "facebook" },
          { val: this.nameIg, name: "instagram" },
        ].forEach((item) => {
          if (item.val.length) {
            this.getLeadData(item);
          }
        });
      }
    },
    async getLeadData(item) {
      this.isLoading = true;
      try {
        const data = await this.fetchLead({
          name: item.val.trim(),
          params: {
            facebook: item.name === "facebook" ? true : undefined,
            instagram: item.name === "instagram" ? true : undefined,
            sdr: this.sdr,
            domain: this.domain,
          },
        });
        if (item.name === "instagram") {
          this.requestIgData = data.text;
        }
        this.isLoading = false;
        this.status = "ok";
      } catch (e) {
        this.status = "error";
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: #ff5252 !important;
  margin-left: 10px;
}
.text-field {
  &__wrap {
    display: flex;
    width: 100%;
    position: relative;
    &.hidden {
      display: none;
    }
  }
  &__input {
    width: 100%;
    ::v-deep {
      .v-text-field .v-label {
        left: 25px !important;
      }
      .v-text-field .v-label--active {
        left: 0 !important;
      }
      input {
        padding-left: 30px !important;
      }
    }
  }
  &__icon {
    left: 10px;
    top: 19px;
    position: absolute;
    font-size: 16px;
  }
}
.request-info {
  display: flex;
  flex-direction: column;
  .cell {
    &:first-child {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    &__title {
      width: 100%;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: #1f2325;
      margin-bottom: 4px;
    }
  }
}
.static-loader {
  position: static !important;
}
</style>
